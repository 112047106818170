<template>
  <div class=" mt-2">
    <b-breadcrumb class="breadcrumb-pipes">
      <b-breadcrumb-item href="/settings/admin-panel/">
          Admin Panel
        </b-breadcrumb-item>
        <b-breadcrumb-item href="/settings/user-role">
          Roles and Permissions
        </b-breadcrumb-item>
        <b-breadcrumb-item href="/settings/personal-settings">
          Personal Settings
        </b-breadcrumb-item>
      </b-breadcrumb>
    <b-card class="mb-0" :title="$t('settings.historical_prices.titles.manage_historical_prices')">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row align-h="between">
            <b-col cols="12" md="3">
             <small><strong>Last Update:</strong> {{ lastRefresh }}</small>
            </b-col>

   
            
            <b-col cols="12" md="6">
              <div class="d-flex justify-content-end">
                <b-form-group class="mr-2">
                <b-button variant="success" @click="downloadClicked">
                  <span class="text-nowrap"> Download Tickers </span>
                </b-button>
              </b-form-group>
                <b-form-group class="mr-2">
                  <div>
                  
                    <b-form-file accept=".xls, .xlsx" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="importExcelFile" ref="file-input" />
                  </div>
                </b-form-group>
              </div>
              
              </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative p-2"
          :items="allData"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('general_messages.no_matching_recors_found')"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(index)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.index + 1 }}</strong>
              </span></b
            >
          </template>
          <!-- Column: Currency -->
         

          <template #cell(Date)="data">
          <span>{{dateFormat(data.item.Date)}}</span>

          </template>

          


   

          <!-- Column: Actions -->
          <template #cell(Price)="data">
            <b-form-input
            type="number"
                min="0"
                max="1"
                v-model="data.item.Price"
                class="d-inline-block mr-1"
              />
          </template>

          <template #cell(AccruedInterest)="data">
            <b-form-input
            :disabled="!data.item.isBond"
            type="number"
                min="0"
                max="1"
                v-model="data.item.AccruedInterest"
                class="d-inline-block mr-1"
              />
          </template>

          <template #cell(Confirm)="data">
            <b-button variant="success" class="btn btn-sm" @click="updatePrice(data.item)">Update</b-button>
           
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            <span class="text-muted"
                >{{$t('general_text.showing')}} {{ dataMeta.from }} {{$t('general_text.to')}} {{ dataMeta.to }} {{$t('general_text.of')}}
                {{ dataMeta.of }} {{$t('general_text.entries')}}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
     
    </b-card>

    <b-modal
        id="modal-date"
        cancel-variant="outline-secondary"
        ok-title="Confirm"
        @ok="handleUpdateDate"
        cancel-title="Close"
        :no-close-on-backdrop="true"
        centered
      >
        <template #modal-header="{ close }">
          <span>Select Dates</span>
        </template>
        <b-form>
          <b-form-group
            label="Date"
            label-for="date"
          >
            <flat-pickr
              id="date"
              input-id="date"
              v-model="downloadDate"
              class="form-control"
              :config="{
                locale: { firstDayOfWeek: 1 },
                dateFormat: 'd/m/Y',
              }"
            />
          </b-form-group>
        </b-form>
      </b-modal>

  </div>

  
  


</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { BFormFile } from 'bootstrap-vue';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css"; // Import the Flatpickr CSS
import { BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormFile,
    flatPickr,
    BBreadcrumb, BBreadcrumbItem
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      downloadDate:null,
      excelJson: {},
      excelJsonSheet2: {},
      allData: [],
      currencyCreateModal: false,
      allCreatedCurrencies: [],
      accessLevel: null,
      filterModal: null,
      updatePermloading: false,
      createCurrencyloading: false,
      newCurrency: null,
      defaultRate:null,
      filterOn: ["Residency"],
      tableColumns: [
        {
          key: "index",
          label: "",
        },
        { key: "Date", label:'Date', sortable: true, thStyle:'width:10%;'},

        {
          key: "Ticker",
          label: this.$t('settings.historical_prices.table.ticker'),
          sortable: true,
        },
        { key: "Price", label:this.$t('settings.historical_prices.table.price'), sortable: true, thStyle:'width:25%;'},
        { key: "AccruedInterest", label:this.$t('settings.historical_prices.table.accrued_interest'), sortable: true, thStyle:'width:25%;'},
        { key: "Confirm", label:'Confirm', sortable: true, thStyle:'width:5%;'},


      ],
      perPage: localStorage.getItem("currencyShow") ?? 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
      lastRefresh:null,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    const today = moment(); // Get current date
    let lastBusinessDay = today.clone(); // Clone today's date

    // Keep subtracting one day until we reach a business day (excluding weekends)
    lastBusinessDay.subtract(1, 'days');

    while (lastBusinessDay.day() === 0 || lastBusinessDay.day() === 6) {
        lastBusinessDay.subtract(1, 'days');
    }

    this.downloadDate = lastBusinessDay.format('DD/MM/YYYY');
    this.listHistoricalData();
  },
  methods: {
    listHistoricalData() {
      this.loading = true;
      store
        .dispatch("settings/listHistoricalData")
        .then((res) => {
          this.allData = res.data.data;
          this.lastRefresh = res.data.lastRefresh

          this.totalRows = res.data.data.length;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },
    refreshData() {
      this.loading = true;
      store
        .dispatch("settings/refreshHistoricalData")
        .then((res) => {
          this.listHistoricalData();
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },

    updatePrice(data) {
      this.loading = true;
      store
        .dispatch("settings/updateHistoricalData", {'id' : data.id, 'Price' : data.Price, 'AccruedInterest':data.AccruedInterest })
        .then((res) => {
       

          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },

    historicalDataExport() {
      this.loading = true;
      store
        .dispatch("settings/historicalDataExport", {date:this.downloadDate})
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "prices.xlsx");
          document.body.appendChild(link);
          link.click();
          this.loading = false;
          this.downloadDate=  moment(new Date()).format('DD/MM/YYYY')
;

        })
        .catch((error) => {
          console.log(error);
        });
    },


    importExcelFile(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];

        let worksheet = workbook.Sheets[sheetName];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        let controlExcelColumn = 0;
        let matchRegex = /(Date|Ticker|Price|AccruedInterest)/g;
        

        if (this.excelJson.length > 0 && this.excelJson != {}) {
          for (let x in this.excelJson[0]) {
            if (x.match(matchRegex)) {
              controlExcelColumn += 1;
            }
          }
          if (controlExcelColumn === 4) {
            this.excelJson.forEach((item) => {
              if (!item['Date'].toString().includes('-')) {
                item['Date'] = self.excelDateToJSDate(item['Date']);
              }
            });

            store
              .dispatch("settings/importHistoricalData", this.excelJson)
              .then((res) => {
                self.$refs['file-input'].reset();
                self.listHistoricalData();

                self.$swal({
                  title: `${res.data.success_added_data == 0 ? 'warning' : 'success'}`,
                  //   text: `${res.data.success_added_data} data successfully added. But ${res.data.error_rows} rows didn't add. Please check your rows and load those rows with a new excel `,
                  html: `
                  <span style="${res.data.success_added_data == 0 ? 'display:none' : ''}">${res.data.success_added_data} data successfully added.</span>
                  <br>
                  <span style="${res.data.error_rows.length > 0 ? '' : 'display:none'}">
                  <span style="${res.data.success_added_data == 0 ? 'display:none' : ''}">But  </span> Row(s) <b style="color:red">${res.data.error_rows}</b> weren't added. Please check your rows because, these record(s) already exist and load those rows with a new excel
                  </span>`,
                  icon: `${res.data.success_added_data == 0 ? 'warning' : 'success'}`,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              })
              .catch((error) => {
                self.$swal({
                  title: 'Error!',
                  text: 'Oops, Something went wrong while loading excel, please try again',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });

                console.log(error);
              });
          } else {
            self.$swal({
              title: 'Error!',
              text: 'Oops, This excel table headers not correct. Make sure the headers are correct',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },
    excelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      return date_info.getDate() + '-' + (date_info.getMonth() + 1) + '-' + date_info.getFullYear();
    },

 
  
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.currency.messages.database_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    existingCurrencyMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.currency.messages.currency_is_exist'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    currencyNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.currency.messages.currency_not_found'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createCurrencyToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('settings.currency.messages.currency_create_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    downloadClicked() {
      

        this.$bvModal.show("modal-date");
     
    },

    handleUpdateDate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdateDuplicate();
    },

    modalUpdateDuplicate(val) {
    

      this.$nextTick(() => {
        this.$bvModal.hide("modal-date");
      });
      this.historicalDataExport()


    },
    dateFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
  



  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
